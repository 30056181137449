@keyframes staticImageOverlayShowUp {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.contact {
    padding-top: 60px;

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 95%; 
        margin: 0 auto;
    }

    &__form {
        width: 40%;
        padding-right: 3rem;


        &__container {

            button {
                width: 100%;
                margin: 1rem 0;
                border: none;
                padding: .7rem;
                background-color: lighten($color: $navy, $amount: 10);
                border-radius: 3px;
                transition: .3s;
                
                &:hover {
                    background-color: $navy;
                }
            }
        }
    }


    &__afterSubmitAlert {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        
        p {
            font-size: 1.7rem;   
        }


        button {
            border: none;
            padding: .5rem 1.5rem;
            background-color: #A3DA8D;
            border-radius: 5px;
        }


        &__header {
            margin: 0 5rem;
        }
    }


    &__more {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        & > div {
            width: 50%;
        }
    }

    &__socialMedia {
        display: flex;
        align-items: center;
        margin-left: 1rem;

        & > div {
            display: flex;
            align-items: center;
            margin-left: 1rem;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2rem;
                height: 2rem;
                border-color: lightgrey;
                cursor: pointer;
                margin: 0 .5rem;

                svg {
                    fill: $navy;
                }
            }
        }
    }

    &__images {
        background-position: center !important;
        background-repeat: no-repeat !important;
        width: 70%;
        height: 75vh;
        background-size: cover !important;
        position: relative;

        div {
            width: 100%;
            height: 100%;
            position: absolute;
            background: linear-gradient(45deg, $navy, transparent);
            left: 0;
            top: 0;
            opacity: 0;
            animation: staticImageOverlayShowUp 1s 1s forwards alternate;
        }
    }


    @media (max-width : $md) {
        .contact {
            &__images { display: none;}

            &__form {
                width: 100%;
                padding: 0;
            }

            &__more {
                width: 97%;
                flex-direction: column;
                align-content: flex-start;

                & > div { width: 100%;}
            }
        }
    }
}