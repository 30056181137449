.layoutItem {
    width: 23%;
    margin: .5rem;
    margin-bottom: 1rem;

    &__details {

        & > div {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            & > div {
                width: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            svg {
                width: 1.5rem;
                height: 1.5rem;
                transition: .3s;
                transform: translateY(20%);
                opacity: 0;
                cursor: pointer;
                fill: $defaultTextColor;
            }
        }

        p {
            width: 80%;
            font-size: 1.3rem;
            margin: 0;
            margin-top: .5rem;
            cursor: pointer;
            color: $defaultTextColor;
            padding-right: 1rem;
        }

        span {
            color: grey;
            font-size: .8rem;
        }
    }

    &__imageCover {
        width: 100%;
        min-height: 250px;
        position: relative;
        cursor: pointer;
        background-color: lighten($color: $navy, $amount: 25%);

        img {
            width: 100%;
            height: 250px;
        }


        &::after {
            content: "";
            width: 100%;
            height: 100%;
            background-color: lighten($color: $navy, $amount: 10%);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            transition: .3s;
            opacity: 0;
        }

        &:hover {
            &::after {
                opacity: .6;
            }
        }
    }

    &:hover {
        .layoutItem {
            &__details {
                svg {
                    transform: translateY(20%) translateX(5px);
                    opacity: 1;
                }
            }

        }
    }

    &--fa {
        .layoutItem {
            &__details {
                direction: rtl;
            }

            
        }
        &:hover {
            .layoutItem {
                &__details {
                    svg {
                        transform: translateY(20%) translateX(-5px) !important;
                    }
                }
            }
        }
    }
  
    @media (max-width : $lg) {
        width: 30%;
    }

    @media (max-width : $md) {
        width: 45%;
        &:not(&:last-child) {
            margin-bottom: 5rem;
        }
    }


    @media (max-width : $sm) {
        flex: 1 0 100%;

        .layoutItem {
            &__details {
                
                div {
                    align-items: flex-start;

                    div {
                        justify-content: flex-end;
                        padding-top: .5rem;

                        svg {
                            opacity: 1;

                        }
                    }
                }
            }
        }
    }
}