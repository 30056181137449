@import "./layout/";

body {
    font-family: 'Kaisei Tokumin' , 'Vazir', serif;
    background-color: $grey;
}

.nestedSlider {
    display: flex;
    
    & > div {
    width: 700px;
    }

    @media (max-width : $sm) {
        img {
            width: 100%;
        }
    }
}


.faDir {
    direction: rtl;
}