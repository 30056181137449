.singleExhibition {
    position: relative;
    margin-top: 100px;
    
    &__images {
        width: 100%;

        img {
            max-width: 70%;
        }
        // display: flex;
        // align-items: center;
        // flex-direction: column;

        // div {
        //     width: 400px;
        //     margin: 1rem 0;
        //     img {
        //         width: 100%;
        //         cursor: pointer;
        //     }
        // }
    }

    &::after {
        content: "";
        width: 100%;
        height: 0;
        background: linear-gradient(180deg, $navy, transparent);
        position: absolute;
        top: -100px;
        left: 0;
        z-index: -2;
        transition: .6s .6s;
    }

    &--loaded {

        &::after {
            height: calc(10% + 200px);
        }
    }
}