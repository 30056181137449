.tagbar {
    display: flex;
    border: 2px solid lighten($color: $navy, $amount: 25);
    width: fit-content;
    flex-wrap: wrap;


    div {
        margin: 0 .5rem;
        color: $navy;
        font-size: .8rem;
        font-weight: 900;
    }

}