$beige : #F0E5CF;
$grey : #F7F6F2;
$lightblue : #C8C6C6;
$navy : #92afbc;
$defaultTextColor : #5c5c5c;

$darkGrey : #929292;

$menuBackgroundBgColor : #2e4868;

$radius : 5px;

$cubic : cubic-bezier(0.86, 0, 0.07, 1);


$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
