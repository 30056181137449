.imageLightBox {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000000f2;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 99;
    overflow-y: auto;
    padding-top: 65px;
    padding-bottom: 10px;

    p {
        position: fixed;
        color: white;
        left: 20px;
        top: 65px;
        font-weight: 800;
        cursor: pointer;
    }

    img {
        max-width: 80%;
    }


    @media (max-width : $lg) {
        align-items: center;
    }
}