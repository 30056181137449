%textInput {
    border-radius: 3px;
    outline: none;
    padding: 0 .5rem;
    border: 1px solid lightgrey;
    width: 100%;
}

.customInput {
    margin: 1rem 0;
    display: flex;
    flex-direction: column-reverse;

    * {
        transition: .3s;
    }

    label {
        margin: .8rem 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: $defaultTextColor;
        
        span {
            color: red;
            margin-left: .5rem;
        }

        div {
            width: 0rem;
            height: 0rem;
            background-color: $navy;
            border-radius: 4px;
            transition: .4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        }
    }

    input , textarea {
        &:focus {
            
            & + label {
                
                div {
                    width: 1rem;
                    height: 1rem;
                    margin-left: 1rem;
                }
            }
            
        }
    }
    
    textarea {
        @extend %textInput;
        min-height: 5.5rem;
        max-height: 10rem;
        padding-top: .5rem;
    }

    input {
        height: 2.5rem;
        @extend %textInput;
    }

    &--fa {
        text-align: right;

        textarea,input {
            direction: rtl;
        }
    }


    &--error {
        label {
            color: rgba(255, 0, 0, 0.836);
        }
        input , textarea {
            border-color: rgba(255, 0, 0, 0.322);

        }
    }
}