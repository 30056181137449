.homePostBlock {
    padding: 2rem;
    width: 300px;
    margin-right: 5rem;
    
    &__title {
        
        p {

            display: flex;
            flex-direction: column;
            font-size: 1.5rem;
            margin: 0;

            span {
                width: 100%;
                height: 2px;
                background-color: lighten($color: $navy, $amount: 20);
                margin-top: .3rem;
            }
        }
    }

    &__trigger {
        width: 100%;
        background-color: transparent;
        border: none;
        color: $navy;
        text-align: right;
    }


    &--fa {
        text-align: right;

        .homePostBlock {

            &__title {
                div {
                    direction: rtl;
                }
            }

            &__trigger {
                text-align: left;
            }
        }
    }

    @media (max-width : $sm) {
        width: 100%;
        padding: 0;
        margin: 1.5rem 0;
        .homePostBlock {

            &__trigger {
                width: 100%;
            }
        }
    }
}