.notFound {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        font-size: 2rem;
        margin: 0;
        color: $defaultTextColor;

        &:first-child {
            font-size: 13rem;
        }
        
        &:last-child {
            cursor: pointer;
            transition: .3s;
            
            &:hover {
                color: $navy;
            }
        }
    }
}