.languageTrigger {
    position: relative;
    overflow: hidden;
    transition: .3s;
    max-width: 100px;
    
    p {
        cursor: pointer;
        display: inline;
        user-select: none;
    }

    &--hide {
        max-width: 0;
        opacity: 0;
    }
}