.singleBlog {
    margin-top: 100px;
    position: relative;

    &__intro {

        h1 {
            margin-bottom: 1rem;
        }
        
        p {
            margin-left: 1rem;
            color: $darkGrey;
        }
    }

    &__description {
        p {
            font-size: 1.3rem;
        }
    }

    &__slider {
        width: 100%;

        &__item {
            width: 100%;
            height: 40rem;
            background-position: center center !important;
            background-size: contain !important;
            background-repeat: no-repeat !important;

        }
    }

    &::after {
        content: "";
        width: 100%;
        height: 0;
        background: linear-gradient(180deg, $navy, transparent);
        position: absolute;
        top: -100px;
        left: 0;
        z-index: -2;
        transition: .6s .6s;
    }
    
    &--loaded {
        &::after {
            height: calc(10% + 200px);
        }
    }
}