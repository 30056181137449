.singleArchive {
    position: relative;
    margin-top: 100px;

    &__details {
        p {
            color: $darkGrey;
        }
    }

    &__images {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        div {
            width: 400px;
            margin: 1rem 0;
            img {
                width: 100%;
                cursor: pointer;
            }
        }
    }
    
    &::after {
        content: "";
        width: 100%;
        height: 0;
        background: linear-gradient(180deg, $navy, transparent);
        position: absolute;
        top: -100px;
        left: 0;
        z-index: -2;
        transition: .6s .6s;
    }

    &--loaded {

        &::after {
            height: calc(10% + 200px);
        }
    }


    @media (max-width : $sm) {
        .singleArchive {
            &__images {
                margin-top: 1rem;
                div {
                    width: 90% !important;
                    margin: 1rem auto;
                }
            }
        }
    }
}