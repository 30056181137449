@keyframes animateSpinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.spinner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;


    svg {
        width: 2rem;
        height: 2rem;
        fill: $navy;
        animation: animateSpinner 1s forwards infinite;
    }
}