@keyframes grows {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes close {
    to {
        transform: translateX(100%);
    }
}


@keyframes getMenuTitleABullet {
    from {
        transform: translateY(-50%) scale(0);
    }
    to {
        transform: translateY(-50%) scale(1);
    }
}

@keyframes getDistanceFromBullet {
    from {
        padding-right: 0;
    }
    to {
        padding-right: 50px;
    }
}
@keyframes reversedGetDistanceFromBullet {
    from {
        padding-left: 0;
    }
    to {
        padding-left: 50px;
    }
}

.menu {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    background-color: $menuBackgroundBgColor;
    color: white;
    right: 0;
    top: 0;
    padding-top: 60px;
    animation: grows 1s $cubic forwards alternate;
    z-index: 99;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    &__brandIcon {
        img {
            width: 120px;
            margin-bottom: 1rem;
        }
    }

    &__author {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: $beige;
        margin-bottom: auto;

        p {
            margin: 0 !important;
        }

        &__name {
            font-size: 2rem;
            margin-bottom: .5rem;
        }   
    }

    &__container {
        padding: 0 6rem;
        width: 100%;
        display: flex;
        height: 60%;
        align-items: center;
        justify-content: space-around;
        margin-top: auto;
        margin-bottom: auto;
    }


    &--en {
        .menu {

            &__title {
                text-align: left;
                p {
                    animation: none;
                    animation: reversedGetDistanceFromBullet .3s .9s forwards alternate;
                }

                &::after {
                    left: 0;

                }
            }

            &__itemDirectory {
                flex-wrap: wrap;
                .menuItem {
                    text-align: left !important;
                    p {
                        text-align: left;
                        &::after {
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    &--close {
        animation: close .5s $cubic forwards alternate;
    }


    @media (max-width : $lg) {
        .menu {
            &__container {
                padding: 0;
                flex-direction: column;
                align-items: center;
                height: 80%;
            }


            &__itemDirectory {
                justify-content: center;
            }

            &__title {
                p {
                    margin: 0;
                }
            }
        }
    }
}