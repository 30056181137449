@keyframes initialAnimateForGalleryBox {
    from {
        height: 100%;
    }
    to {
        height: 0%;
    }
}

.galleryBox {
    display: flex;
    justify-content: space-between;

    padding: 1rem;

    .galleryBoxTitle {
        flex: 1;
        p {
            &:first-child {
                font-size: 2.5rem;
                font-weight: bold;
            }

        }
    }

    .galleryBoxColumn {
        margin: .5rem;
        transition: .5s $cubic , opacity 0s , transform 0.1s;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;


        &__InitialOverlayAnimator {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 9;
            background : $navy;
            animation: initialAnimateForGalleryBox 3.5s .3s forwards alternate cubic-bezier(0.075, 0.82, 0.165, 1);
        }

    }


    .galleryBoxImageContainer {
        flex: 3;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            cursor: pointer;
        }
    }
}