.aboutImageContainer {
    position: relative;
    margin: 1rem 0;
    
    
    &__imageDirectory {
        width: 100%;
        display: flex;
        img {
            width: 1px;
            position: relative;
            z-index: 4;
            flex: 1 0 10px;
            margin: 0 .3rem;
            max-width: 260px;
        }

    }

    &__desc {
        margin-left: 1rem;
        font-size: 1rem;
        color: grey;
        margin-top: 1rem;
        position: relative;
        z-index: 4;
    }



    &::after {
        content: "";
        position: absolute;
        left: -7%;
        top: 50%;
        transform: translateY(-50%);
        width: 2rem;
        height: 2rem;
        background-color: lighten($color: $menuBackgroundBgColor, $amount: 60%);
        z-index: 2;
    }
}