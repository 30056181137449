.appHeader {
    border-bottom: 2px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    position: relative;
    z-index: 55;
    transition: .3s;
    overflow: hidden;
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: 9999;
    top: 0;
    left: 0;

    .brandName {        
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            cursor: pointer;
        }
    }
    
    .appHeaderIconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .5rem;
        cursor: pointer;
        user-select: none;

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

}