.about {
    
    &__container {
        width: 90%;
        margin: 0 auto;
        margin-top: 8rem;
    }
    
    &__intro {
        width: 100%;
        display: flex;
        
        &__image {
            img {
                width: 15rem;
            }
        }

        &__details {
            font-size: 8rem;
            p {
                margin: 0;
                margin-left: 2rem;
                line-height: 122px;
            }
        }
    }

    &__content {
        width: 90%;
        margin: 0 auto;
        margin-top: 2rem;
        position: relative;

        & > p {
            margin: 5rem 0;
        }
        
        &::after {
            content: "";
            width: .5rem;
            height: 100%;
            position: absolute;
            background-color: #cfcfcf1f;
            left: -5.5%;
            top: 0;
            z-index: 1;

        }
    }

    &__nutshell {
        display: flex;
        flex-direction: column;

        p {
            font-weight: 900;
            margin: 0;
            font-size: 1.1rem;
            margin: 1rem 0;
        }
    }

    @media (max-width : 992px) {
        .about {
            &__intro {
                display: flex;
                flex-direction: column;
                &__details {
                    font-size: 4rem;
                    font-weight: 900;
                    p {
                        line-height: 1;
                        margin-left: 0rem;
                    }
                }
            }
        }
    }


    @media (max-width : 576px) {
        .about {
            &__intro {
                &__image {
                    img {width: 100%;}
                }

                &__details {
                    p {
                        margin: 1rem 0 0 0 !important;
                    }
                }
            }
        }
    }

}