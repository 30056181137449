.productSliderController {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    user-select: none;
    background-color: white;
    padding: 2rem;
    display: none;

    div {

        svg {
            width: 2.5rem;
            height: 2.5rem;
            cursor: pointer;
            transition: .3s;
            fill: darkgrey;
        }
        
        &:last-child {
            margin-top: 1rem;
        }


        &:hover {
            svg {
                fill: grey;
            }
        }
    }

    @media (max-width : $md) {
        position: relative;
        display: flex;
        width: 60%;
        justify-content: space-between;
        padding: 0;
        background-color: transparent;
        margin: 1rem auto;

        & > div {
            margin: 0 !important;

            &:first-child {
                transform: rotate(-90deg);
            }

            &:last-child {
                transform : rotate(-90deg)
            }
        }


        @media (max-width : $sm) {
            width: 100%;
        }
    }
}