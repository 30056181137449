.singleEvent {
    position: relative;
    margin-top: 100px;

    &__title {
        margin-bottom: 2rem;

        div {
            p {
                color: $darkGrey;
            }
        }
    }

    &__desc {
        img {
            max-width: 100%;
        }
    }


    
    &::after {
        content: "";
        width: 100%;
        height: 0;
        background: linear-gradient(180deg, $navy, transparent);
        position: absolute;
        top: -100px;
        left: 0;
        z-index: -2;
        transition: .6s .6s;
    }

    &--loaded {

        &::after {
            height: calc(10% + 200px);
        }
    }
}