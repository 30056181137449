.menuItem {
    text-align: right;
    position: relative;
    margin: 1rem 0;
    
    button {
        background-color: transparent;
        border: none;
        font-size: 2rem;
        text-align: right;
        margin-left: auto;
        color: $beige;

        p {
            margin: 0;
            transition: .1s;
            position: relative;

            span {
                position: relative;
                z-index: 9999;
                
            }


            &:hover {
                text-decoration: underline;
            }
        }
    }

    &--active {
        p {
            text-decoration: underline;
            font-weight: 900;
        }
    }
    
    @media (max-width : $lg) {
        p {
            font-size: 1.5rem;
        }
    }

    @media (max-width : $sm) {
        text-align: right;
        padding-left: 1rem;

        p {
            font-size: 1.3rem;
        }
    }

}