.home {
    width: 100%;
    height: 100vh;
    

    &__slider {

        &__item {
            width: 100%;
            height: 100vh;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            padding: 80px 1rem 0 4rem;

            &__innerContainer {
                width: 50%;
            }

        }

        &__controller {
            position: absolute;
            bottom: 2rem;
            left: 1rem;
            z-index: 9;
            display: flex;
            align-items: center;
            user-select: none;
            
            div {
                background-color: $lightblue;
                padding: .5rem;
                margin: 0 .5rem;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                
                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                }

            }
        }
    }


    &__otherGoddamnContainerForNoPurpose {
        width: 90%;
        margin: 0 auto;


        &__intro {
            font-size: 2rem;
            p {
                margin: 0;
                margin-top: 2rem;
            }
        }
        
        &__itemContainer {
            display: flex;
            align-items: center;
        }


        &--fa {
            .home {
                &__otherGoddamnContainerForNoPurpose {
                    &__intro {
                        text-align: right;
                    }
                }
            }
        }

    }



    @media (max-width : $sm) {
        .home {
            &__slider {
                &__controller {
                    width: 90%;
                    left: 5%;
                    justify-content: space-around;
                }
            }

            &__otherGoddamnContainerForNoPurpose {
                &__itemContainer {
                    flex-direction: column;
                }
            }
        }
    }
}