.product {
    
    &__innerContainer {
        display: flex;
        justify-content: space-between;
        margin-top: 100px;
        width: 100%;
    }


    

    &__images {
        width: 40%;
        position: relative;
        display: flex;
        flex-direction: column;
        
        &__slide {
            width: 100%;
            height: 400px;
            background-position: top center !important;
            background-size: contain !important;
            background-repeat: no-repeat !important;
        }
    }

    &__details {
        width: 55%;
        
        &__title {
            font-size: 2rem;
            margin-top: 0;
            margin-bottom: 1rem !important;
        }    

        &__description {
            margin: 1rem 0;
        }

        &__shortDescription {
            color: $darkGrey;
        }
    }

    &__price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $navy;
        padding: 0 1rem;
        color: white;
        span {
            font-weight: 900;
        }
    }


    @media (max-width : $lg) {
        .product {
            &__images {
                &__slide {
                    height: 300px;
                }
            }
        }
    }


    @media (max-width : $md) {
        .product {
            &__innerContainer {
                flex-direction: column;
            }

            &__images {
                width: 100%;
                flex-direction: column-reverse;
            }

            &__details {
                width: 100%;
            }
        }
    }

    @media (max-width : $sm) {
        .product {

            &__price {
                margin-bottom: 1rem;
            }

            &__details {
                margin-top: 1rem;
            }

            &__images {
                &__slide {
                    height: 320px;   
                }
            }
        }
    }
}